import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'
import { getInstance } from '@/auth/index.js'
import { onError } from 'apollo-link-error'
import store from '@/store'
const getHeaders = async () => {
    const headers = {}
    const authService = getInstance()

    const getToken = async () => {
        if (authService.isAuthenticated) {
            const token = await authService.getTokenSilently()
            headers.authorization = token ? `Bearer ${token}` : ''
            return headers
        } else {
            authService.loginWithRedirect()
        }
    }

    // If loading has already finished, check our auth state using `getToken()`
    if (!authService.loading) {
        return getToken()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return getToken()
        }
    })
}

const errorHandler = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        console.log('GraphQL ERRORS:  ' + graphQLErrors)
    }
    if (networkError) {
        switch (networkError.statusCode) {
            case 401: {
                //statements;
                console.log(networkError.result.message)
                store.dispatch('responseMessage', {
                    type: 'error',
                    title: 'Error',
                    text:
                        'You are not authorized for this action.  You may need to logout and log back in again. ',
                })
                break
            }
            case 403: {
                //statements;
                console.log(networkError.result.message)
                store.dispatch('responseMessage', {
                    type: 'error',
                    title: 'Error',
                    text:
                        'You are not authorized for this action.  You may need to logout and log back in again. ',
                })
                break
            }
            case 500: {
                store.dispatch('responseMessage', {
                    type: 'error',
                    title: 'Error',
                    text:
                        'A Server error occurred while processing your request.  ',
                })
                break
            }
            default: {
                console.log(networkError)
                store.dispatch('responseMessage', {
                    type: 'error',
                    title: 'Error',
                    text: networkError.result.message,
                })
                break
            }
        }
    }
})

// eslint-disable-next-line no-unused-vars
const authLink = setContext(async (_, { headers }) => {
    // Use your async token function here:
    // Return the headers to the context so httpLink can read them
    return {
        headers: await getHeaders(),
    }
})

// Install the vue plugin
Vue.use(VueApollo)
// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'
// Http endpoint
const httpEndpoint =
    process.env.VUE_APP_GRAPHQL_HTTP || 'https://api.mydocport.com/graphql'

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    //link: authLink,
    link: authLink.concat(errorHandler),
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null, // process.env.VUE_APP_GRAPHQL_WS || "ws://api.localhost:8000/graphql",
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: myLink
    //link: authLink,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...
    getAuth: () => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
    },
}

// Call this in the Vue app file
export function createProvider(options = {}) {
    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options,
    })
    apolloClient.wsClient = wsClient

    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log(
                '%cError',
                'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                error.message
            )
        },
    })

    return apolloProvider
}

export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(AUTH_TOKEN, token)
    }
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
            '%cError on cache reset (login)',
            'color: orange;',
            e.message
        )
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN)
    }
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
            '%cError on cache reset (logout)',
            'color: orange;',
            e.message
        )
    }
}
