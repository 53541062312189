<template>
    <v-footer :fixed="fixed" app>
        <v-row justify="center">
            <v-btn color="primary" text class="my-2">
                &copy; {{ new Date().getFullYear() }}
                <strong>White Deer Group</strong>
            </v-btn>

            <v-btn
                v-if="checkTheUser({ perm: 'client_admin' })"
                color="primary"
                text
                class="my-2"
                to="/contact"
            >
                Contact
            </v-btn>
            <v-btn
                v-if="checkTheUser({ perm: 'client_admin' })"
                color="primary"
                text
                class="my-2"
                to="/about"
            >
                About
            </v-btn>
        </v-row>
    </v-footer>
</template>

<script>
import { authGate } from '@/mixins/authGate'
export default {
    name: 'PageFooter',
    mixins: [authGate],
    data() {
        return {
            fixed: false,
        }
    },
    computed: {
        thisYear: function() {
            return new Date().getFullYear()
        },
    },
    methods: {
        checkTheUser(item) {
            return this.checkUser(item.perm)
        },
    },
}
</script>
