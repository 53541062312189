var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100%","max-height":"175px"},attrs:{"src":require("@/assets/logo.png"),"alt":"MyDocPort"}})]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',[_vm._v(" Welcome to the "+_vm._s(this.$store.getters.userForPortalAccess.client .name)+" MyDocPort! Visit our manual website or go to the MyDocPort Admin dashboard. ")])]),(
                        this.$store.getters.userForPortalAccess
                            .user_type === 'client_user' ||
                            this.$store.getters.userForPortalAccess
                                .user_type === 'client_admin'
                    )?[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h5',{staticClass:"body-1"},[_vm._v(" Announcements "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.showMessage}},[_c('v-icon',[_vm._v("mdi-email")])],1)],1)]),(_vm.showNews)?_c('NewsCard',{ref:"newsCard",staticStyle:{"font-size":"15px","line-height":"1.5","margin":"10px","margin-top":"-15px"},on:{"go-back":_vm.goBack,"close-dialog":function($event){_vm.showNews = false}}}):_vm._e()]:_vm._e()],2),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"secondary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-earth-arrow-right")])],1),_c('v-list-item-content',[_vm._v(" Website for All Manuals "),_c('v-list-item-subtitle',[_vm._v(" View manuals, training and forms. ")])],1)],1),_c('v-card-text',[_c('ApolloQuery',{attrs:{"query":require('@/graphql/output-pool/allOutputPools.gql'),"variables":_vm.query_vars,"deep":""},scopedSlots:_vm._u([{key:"default",fn:function({
                                        result: { loading, error, data },
                                        query,
                                    }){return [(loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(data && !loading)?_c('v-container',_vm._l((data.allOutputPools),function(item){return _c('v-row',{key:item.id,attrs:{"align":"center"}},[(item.index_url)?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.visitContent(
                                                            item.id +
                                                                '/' +
                                                                item.index_url
                                                        )}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-earth-arrow-right")]),_vm._v("Visit "+_vm._s(item.name)+" ")],1)],1):_vm._e()],1)}),1):(error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" There was an error loading data. The server said..."),_c('br'),_c('strong',[_vm._v(_vm._s(error))])]):_vm._e()]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","align":"center"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"secondary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-clipboard-list")])],1),_c('v-list-item-content',[_vm._v(" MyDocPort Dashboard "),_c('v-list-item-subtitle',{attrs:{"align":"left"}},[_vm._v(" View/Download PDFs and MyDocPort Application ")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-auto mr-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.visitDashboard()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-clipboard-list")]),_vm._v("Visit MyDocPort Dashboard ")],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }