<template>
    <v-layout row justify-center>
        <v-dialog
            v-if="responseMessage.modal"
            :value.sync="responseMessage.show"
            max-width="600"
            persistent
        >
            <v-card>
                <v-card-title
                    :class="responseMessage.type"
                    class="headline white--text"
                    >{{ responseMessage.title }}</v-card-title
                >
                <v-divider />
                <v-card-text>{{ responseMessage.text }}</v-card-text>
                <v-layout row justify-center>
                    <v-btn
                        :color="responseMessage.type"
                        @click.native="close"
                        text
                        >{{ $t('ok') }}</v-btn
                    >
                </v-layout>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-else
            v-model="responseMessage.show"
            :color="responseMessage.type"
            top
        >
            {{ responseMessage.text }}
            <v-btn text @click.native="close">{{ $t('close') }}</v-btn>
        </v-snackbar>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'FeedbackMessage',
    computed: mapGetters(['responseMessage']),
    methods: {
        close() {
            this.$store.dispatch('clearMessage')
        },
    },
}
</script>
