<template>
    <div>
        <v-btn
            v-if="windowUrl"
            class="rotated"
            style="margin-right:-65px;"
            absolute
            dark
            bottom
            right
            color="pink"
            @click="launchFeedbackPage"
        >
            <v-icon class="pr-5">mdi-message</v-icon>
            Feedback
        </v-btn>
    </div>
</template>
<script>
export default {
    name: 'feedback',
    data: () => ({
        keywords: '',
        dialog: false,
        iFrameSrc: '',
    }),
    methods: {
        launchFeedbackPage() {
            this.iFrameSrc = this.windowUrl || 'https://google.com'
            window.open(
                this.iFrameSrc,
                'popup',
                'width=700,height=760,left=250'
            )
        },
    },
    computed: {
        windowUrl() {
            if (this.$store.getters.userForPortalAccess.client) {
                return this.$store.getters.userForPortalAccess.client
                    .feedback_iframe_target
            } else {
                return null
            }
        },
    },
}
</script>
<style scoped>
.rotated {
    transform: rotate(-90deg);
}
</style>
