<template>
    <v-container fluid>
        <v-row v-if="show_favorites !== 'yes'">
            <v-col cols="12">
                <v-btn color="primary" :disabled="sortButtonEnabled" :loading="sortLoading" @click="updateSort">Save
                    Sort Order</v-btn>
            </v-col>
        </v-row>
        <ApolloQuery :query="require('@/graphql/output-target/allOutputTargets.gql')" :variables="query_vars" deep>
            <template v-slot="{ result: { loading, error, data }, query }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator v-if="data && !loading" :items="filterOutputTargets(data.allOutputTargets)"
                    :search="keywords" :items-per-page.sync="itemsPerPage" :footer-props="{ itemsPerPageOptions }"
                    :sort-by="sortBy" :sort-desc="sortByDesc">
                    <template v-slot:default="props">
                        <draggable class="row" :list="props.items" v-bind="dragOptions" @start="drag = true"
                            @end="drag = false" @change="onListChange(props.items)">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="row">
                                <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="4" lg="3">
                                    <output-target-card :target.sync="item" :show_favorites.sync="show_favorites"
                                        @delete-clicked="promptForDelete" @edit-clicked="editTarget" />
                                </v-col>
                            </transition-group>
                        </draggable>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
        <v-dialog v-model="deleteDialog" persistent max-width="320" v-if="selectedTarget.name != ''">
            <v-card>
                <v-card-title class="headline">Delete Output Target?</v-card-title>
                <v-card-text>
                    <p>
                        You are about to delete the entire output target
                        <strong>{{ selectedTarget.name }}</strong>. This will remove the output target record as well as
                        any associations with deployment targets and projects.
                        This cannot be undone. Are you sure you want to delete
                        this output target?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
                    <v-btn color="primary darken-1" text @click="deleteTarget(selectedTarget)">{{ $t('delete')
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import OutputTargetCard from '@/views/output-target/OutputTargetCard'
import draggable from 'vuedraggable'
export default {
    name: 'OutputTargetGrid',
    components: {
        'output-target-card': OutputTargetCard,
        draggable,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        output_pool_id: {
            type: String,
        },
        build_type: {
            type: String,
            default: '',
        },
        reload: {
            type: Number,
            default: 0,
        },
        show_favorites: {
            type: String,
            default: 'no',
        },
    },
    data: () => ({
        itemsPerPageOptions: [12, 24, 48],
        itemsPerPage: 12,
        deleteDialog: false,
        selectedTarget: {},
        sortBy: 'sort',
        sortByDesc: false,
        cloneList: [],
        listDirty: false,
        sortLoading: false,
        rando: 0,
        drag: false,
    }),
    watch: {
        reload: function (val) {
            this.rando = val
        },
    },
    computed: {
        sortButtonEnabled: function () {
            if (this.listDirty) {
                return false
            } else {
                return true
            }
        },
        query_vars: function () {
            let out = {
                output_pool_id: this.output_pool_id,
                garbage: this.rando,
            }
            if (this.build_type !== '' && this.build_type !== 'all') {
                out.build_type = this.build_type
            }
            return out
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: this.checkIfMobile(),
                ghostClass: 'ghost',
            }
        },
    },
    methods: {
        checkIfMobile() {
            return this.$vuetify.breakpoint.mobile
        },
        isFavoriteTarget(item) {
            return item.is_favorite
        },
        filterOutputTargets(items) {
            if (this.show_favorites === 'yes') {
                return items.filter(this.isFavoriteTarget)
            }
            return items
        },
        onListChange(data) {
            this.cloneList = data.map((e, index) => ({ id: e.id, sort: index }))
            this.listDirty = true
            return data
        },
        promptForDelete(target) {
            this.selectedTarget = target
            this.deleteDialog = true
        },
        deleteTarget(target) {
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-target/deleteOutputTarget.gql'),
                    // Parameters
                    variables: {
                        id: target.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('target-form-closed', data)
                    this.rando++
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                })
            this.deleteDialog = false
        },

        updateSort() {
            this.sortLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-target/updateTargetSort.gql'),
                    // Parameters
                    variables: {
                        input: this.cloneList,
                    },
                })
                .then(data => {
                    // report success
                    console.log(data)
                    this.rando++
                    this.listDirty = false
                    this.sortLoading = false
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Your sort order has been saved successfully',
                    })
                })
                .catch(error => {
                    // Error
                    this.listDirty = false
                    console.error(error)
                    this.sortLoading = false
                    // update user....
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                })
        },
        editTarget(target) {
            this.$emit('edit-target', target)
        },
        refreshQuery(query) {
            query.refetch()
        },
    },
}
</script>
<style>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
