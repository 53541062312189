<template>
    <v-layout row>
        <v-flex xs12 sm8 offset-sm2 lg4 offset-lg4>
            <ValidationObserver
                ref="observer"
                v-slot="{ validate, reset, invalid }"
            >
                <v-form>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Reset Your Password</span>
                        </v-card-title>
                        <v-card-text>
                            <v-progress-linear
                                v-if="formLoading"
                                indeterminate
                            />
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Password"
                                            rules="required|confirmed:confirmpass"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                v-model="formData.password"
                                                :disabled="formLoading"
                                                :append-icon="
                                                    showpass
                                                        ? 'mdi-eye'
                                                        : 'mdi-eye-off'
                                                "
                                                :type="
                                                    showpass
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                label="Password"
                                                @click:append="
                                                    showpass = !showpass
                                                "
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Confirm Password"
                                            rules="required"
                                            vid="confirmpass"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                v-model="
                                                    formData.confirm_password
                                                "
                                                :disabled="formLoading"
                                                :append-icon="
                                                    showpass
                                                        ? 'mdi-eye'
                                                        : 'mdi-eye-off'
                                                "
                                                :type="
                                                    showpass
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                label="Confirm Password"
                                                @click:append="
                                                    showpass = !showpass
                                                "
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="$emit('password-form-closed')"
                                :disabled="formLoading"
                                >{{ $t('close') }}</v-btn
                            >
                            <v-btn
                                color="primary darken-1"
                                text
                                :loading="formLoading"
                                :disabled="formLoading"
                                @click="updatePass()"
                                >{{ $t('save') }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
        </v-flex>
    </v-layout>
</template>
<script>
import { required, confirmed } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import { updatePassword } from '@/graphql/auth/auth.gql'
setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})
extend('confirmed', {
    ...confirmed,
    message: '{_field_} fields do not match',
})

export default {
    name: 'ResetPassword',
    props: {
        user: {
            type: Object,
            default: () => ({
                id: '',
                password: '',
                confirm_password: '',
            }),
        },
        token: {
            type: String,
        },
    },
    data: () => ({
        error: null,
        showpass: false,
        formLoading: false,
        defaultForm: {
            id: '',
            password: '',
            confirm_password: '',
        },
        formData: {},
    }),
    created() {
        this.formData = { ...this.defaultForm, ...this.user }
    },
    methods: {
        updatePass() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    ;(this.mutation = updatePassword), this.saveForm()
                }
            })
        },
        saveForm() {
            this.formLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: this.mutation,
                    // Parameters
                    variables: {
                        data: {
                            token: this.token,
                            email: this.$route.query.email,
                            password: this.formData.password,
                            password_confirmation: this.formData
                                .confirm_password,
                        },
                    },
                })
                // eslint-disable-next-line no-unused-vars
                .then(data => {
                    // report success
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Password Updated!',
                    })

                    this.formLoading = false
                    this.$router.push({ name: 'login' })
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                    this.formLoading = false
                })
        },
    },
    watch: {
        user: {
            handler: function() {
                this.formData = { ...this.defaultForm, ...this.user }
            },
            deep: true,
        },
    },
}
</script>
