var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.showCreateNews)?_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("WD Administrator Dashboard")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search Tasks...","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1),(_vm.isSuperAdmin)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('output-target-grid',{attrs:{"build_type":"all","show_favorites":"yes","reload":_vm.reloadGrid},on:{"update:reload":function($event){_vm.reloadGrid=$event}}})],1)],1)],1):_vm._e(),(
                this.$store.getters.userForPortalAccess.user_type ===
                    'client_user' ||
                    this.$store.getters.userForPortalAccess.user_type ===
                        'client_admin'
            )?[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('h5',{staticClass:"body-1"},[_vm._v(" Announcements "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.showMessage}},[_c('v-icon',[_vm._v("mdi-email")])],1)],1)]),(_vm.showNews)?_c('NewsCard',{ref:"newsCard",staticStyle:{"font-size":"15px","line-height":"1.5","margin":"10px","margin-top":"-15px"},on:{"go-back":_vm.goBack,"close-dialog":function($event){_vm.showNews = false}}}):_vm._e()]:_vm._e(),_c('v-card-text',_vm._l((_vm.$store.getters.userForPortalAccess
                    .unreadNotifications),function(item){return _c('notification-message',{key:item.id,attrs:{"message":item}})}),1)],2):_vm._e(),(_vm.showCreateNews)?_c('create-news',{on:{"go-back":_vm.goBack}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }