<template>
    <v-app>
        <component v-bind:is="layout"></component>
    </v-app>
</template>

<script>
const default_layout = 'app'

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout'
        },
    },
}
</script>
