<template>
    <div>
        <v-app light v-if="$auth.isAuthenticated && $auth.me">
            <v-navigation-drawer
                v-model="drawer"
                persistent
                enable-resize-watcher
                app
                width="200"
            >
                <nav-menu />
            </v-navigation-drawer>
            <tool-bar :drawer="drawer" @toggleDrawer="drawer = !drawer" />
            <v-main>
                <v-container fluid>
                    <transition name="page" mode="out-in">
                        <router-view />
                    </transition>
                </v-container>
                <feedback-button />
            </v-main>
            <feedback-message />
            <page-footer />
        </v-app>
        <v-card v-else class="mx-auto mt-10 pa-10" max-width="400">
            <v-img class="mx-auto" src="@/assets/logo.png"> </v-img>
            <v-card-title class="pa-10">Loading MyDocPort</v-card-title>
            <v-progress-linear color="primary" indeterminate />
        </v-card>
    </div>
</template>

<script>
import NavMenu from '@/components/Layout/NavMenu'
import ToolBar from '@/components/Layout/ToolBar'
import FeedbackMessage from '@/components/Layout/FeedbackMessage'
import FeedbackButton from '../components/Layout/FeedbackButton'
import PageFooter from '@/components/Layout/PageFooter'
export default {
    components: {
        'nav-menu': NavMenu,
        'tool-bar': ToolBar,
        'feedback-message': FeedbackMessage,
        'feedback-button': FeedbackButton,
        'page-footer': PageFooter,
    },
    props: {
        source: String,
    },

    data: () => ({
        dialog: false,
        drawer: false,
    }),
    computed: {
        authenticated() {
            return this.$auth.isAuthenticated
        },
        authToken() {
            return this.$auth.authToken
        },
        user() {
            if (this.authenticated) {
                return this.$store.getters.userForPortalAccess
            } else {
                return {}
            }
        },
    },
    created() {
        this.drawer = this.$vuetify.breakpoint.mdAndUp
    },
}
</script>
