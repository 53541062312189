<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <img
                            src="@/assets/logo.png"
                            alt="MyDocPort"
                            style="max-width:100%;max-height:175px;"
                        />
                    </v-col>

                    <v-col cols="12" class="text-center">
                        <p>
                            Welcome to the
                            {{
                                this.$store.getters.userForPortalAccess.client
                                    .name
                            }}
                            MyDocPort! Visit our manual website or go to the
                            MyDocPort Admin dashboard.
                        </p>
                    </v-col>
                    <template
                        v-if="
                            this.$store.getters.userForPortalAccess
                                .user_type === 'client_user' ||
                                this.$store.getters.userForPortalAccess
                                    .user_type === 'client_admin'
                        "
                    >
                        <v-col cols="12" class="text-center">
                            <h5 class="body-1">
                                Announcements
                                <v-btn icon @click="showMessage">
                                    <v-icon>mdi-email</v-icon>
                                </v-btn>
                            </h5>
                        </v-col>
                        <NewsCard
                            ref="newsCard"
                            v-if="showNews"
                            style="font-size: 15px;line-height: 1.5;margin: 10px; margin-top: -15px;"
                            @go-back="goBack"
                            @close-dialog="showNews = false"
                        />
                    </template>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="4" sm="6">
                        <v-card>
                            <v-list-item>
                                <v-list-item-avatar color="secondary">
                                    <v-icon dark>mdi-earth-arrow-right</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    Website for All Manuals
                                    <v-list-item-subtitle>
                                        View manuals, training and forms.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-text>
                                <ApolloQuery
                                    :query="
                                        require('@/graphql/output-pool/allOutputPools.gql')
                                    "
                                    :variables="query_vars"
                                    deep
                                >
                                    <template
                                        v-slot="{
                                            result: { loading, error, data },
                                            query,
                                        }"
                                    >
                                        <v-progress-linear
                                            indeterminate
                                            v-if="loading"
                                        />
                                        <v-container v-if="data && !loading">
                                            <v-row
                                                align="center"
                                                v-for="item in data.allOutputPools"
                                                :key="item.id"
                                            >
                                                <v-col
                                                    cols="12"
                                                    align="center"
                                                    v-if="item.index_url"
                                                >
                                                    <v-btn
                                                        color="primary"
                                                        @click="
                                                            visitContent(
                                                                item.id +
                                                                    '/' +
                                                                    item.index_url
                                                            )
                                                        "
                                                    >
                                                        <v-icon left dark
                                                            >mdi-earth-arrow-right</v-icon
                                                        >Visit
                                                        {{ item.name }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-alert type="error" v-else-if="error">
                                            There was an error loading data. The
                                            server said...<br />
                                            <strong>{{ error }}</strong>
                                        </v-alert>
                                    </template>
                                </ApolloQuery>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" sm="6" align="center">
                        <v-card>
                            <v-list-item>
                                <v-list-item-avatar color="secondary">
                                    <v-icon dark>mdi-clipboard-list</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    MyDocPort Dashboard
                                    <v-list-item-subtitle align="left">
                                        View/Download PDFs and MyDocPort
                                        Application
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                                class="ml-auto mr-auto"
                                                color="primary"
                                                @click="visitDashboard()"
                                            >
                                                <v-icon left dark
                                                    >mdi-clipboard-list</v-icon
                                                >Visit MyDocPort Dashboard
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import NewsCard from '@/views/news/NewsCard.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Splash',
    components: {
        NewsCard,
    },
    mounted() {
        if (
            this.$store.getters.userForPortalAccess.user_type ===
                'client_user' ||
            this.$store.getters.userForPortalAccess.user_type === 'client_admin'
        ) {
            this.showNewsCard()
        }
    },

    computed: {
        ...mapGetters({
            authUser: 'authUser',
        }),
        news_query_vars: function() {
            if (this.$store.getters.userForPortalAccess.client) {
                return {
                    client: this.$store.getters.userForPortalAccess.client.id,
                }
            } else {
                return {}
            }
        },
        query_vars() {
            return {
                client_id: this.$store.getters.userForPortalAccess.client.id,
            }
        },
    },
    // add mounted method to get client content pools
    data: () => ({
        showNews: false,
    }),
    methods: {
        // eslint-disable-next-line no-unused-vars
        visitContent(pool_url) {
            window.open(
                process.env.VUE_APP_API_ENDPOINT + '/content/web/' + pool_url,
                '_blank'
            )
        },
        visitDashboard() {
            this.$router.push({ name: 'user-dashboard' })
        },
        showNewsCard() {
            this.$apollo
                .query({
                    query: require('@/graphql/news/allNews.gql'),
                    variables: this.news_query_vars(),
                })
                .then(response => {
                    const data = response.data

                    if (data && data.allNews.length > 0) {
                        this.$nextTick(() => {
                            this.showNews = true
                            // this.$refs.newsCard.menu = true;
                            // this.$refs.newsCard.showModal = true; // Mostrar el modal solo si hay noticias
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        },
        showMessage() {
            this.$nextTick(() => {
                this.showNews = true
                this.$refs.newsCard.menu = true
            })
        },
        goBack() {
            this.showNews = false
        },
    },
}
</script>
