<template>
    <div>
        <div style="padding: 10px">
            <img
                src="@/assets/logo.png"
                alt="MyDocPort"
                style="max-width: 100%; max-height: 100px; cursor: pointer"
                @click="$router.push('/')"
            />
            <div class="text-center">
                Administration Portal
            </div>
        </div>

        <v-list v-for="(item, i) in items" :key="i" dense>
            <v-list-group
                v-if="item.children && checkPermissions(item.permission)"
                v-model="item.model"
                :key="item.text"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
            >
                <v-list-item slot="activator">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    :to="child.route"
                >
                    <v-list-item-action v-if="child.icon">
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item
                v-else-if="checkPermissions(item.permission)"
                :key="item.text"
                :to="item.route"
            >
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import { authGate } from '@/mixins/authGate'

export default {
    name: 'NavMenu',
    mixins: [authGate],
    data: () => ({
        items: [
            { icon: 'mdi-view-dashboard', text: 'Dashboard', route: '/' },
            {
                icon: 'mdi-clipboard-list',
                text: 'Projects',
                route: '/projects',
            },
            { icon: 'mdi-database', text: 'Content', route: '/output' },
            { icon: 'mdi-domain', text: 'Clients', route: '/clients' },
            { icon: 'mdi-account-multiple', text: 'Users', route: '/users' },
            {
                icon: 'mdi-newspaper-variant-outline',
                text: 'Variables',
                route: '/variables',
            },
            {
                icon: 'mdi-bell',
                text: 'Notifications',
                route: '/notifications',
            },
        ],
    }),
}
</script>
