<template>
    <v-layout row>
        <v-flex xs12 sm8 offset-sm2 lg4 offset-lg4>
            <v-card>
                <v-card-title>
                    Forgot Password?
                </v-card-title>
                <v-card-text>
                    No problem. We can help you reset it. Enter the email
                    address attached to your account and click submit and we'll
                    send you a reset link.

                    <ValidationObserver
                        ref="observer"
                        v-slot="{ validate, reset, invalid }"
                    >
                        <v-form>
                            <v-progress-linear
                                v-if="formLoading"
                                indeterminate
                            />
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                v-model="formData.email"
                                                :disabled="formLoading"
                                                label="Email"
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                            color="primary darken-1"
                                            :loading="formLoading"
                                            :disabled="formLoading"
                                            @click="sendPass()"
                                            >{{ $t('submit') }}</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </ValidationObserver>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import { forgotPassword } from '@/graphql/auth/auth.gql'
setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})
extend('email', {
    ...email,
    message: '{_field_} must be a valid email address',
})

export default {
    name: 'ForgotPassword',
    props: {
        user: {
            type: Object,
            default: () => ({
                email: '',
            }),
        },
    },
    data: () => ({
        error: null,
        formLoading: false,
        defaultForm: {
            email: '',
        },
        formData: {},
    }),
    methods: {
        sendPass() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    this.mutation = forgotPassword
                    this.saveForm()
                }
            })
        },
        saveForm() {
            this.formLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: this.mutation,
                    // Parameters
                    variables: {
                        data: {
                            email: this.formData.email,
                        },
                    },
                })
                // eslint-disable-next-line no-unused-vars
                .then(data => {
                    // report success
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Password Link Sent',
                    })
                    this.formLoading = false
                })
                .catch(error => {
                    // Error
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                    // update user....
                    this.formLoading = false
                })
        },
    },
    watch: {
        user: {
            handler: function() {
                this.formData = { ...this.defaultForm, ...this.user }
            },
            deep: true,
        },
    },
}
</script>
