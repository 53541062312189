<template>
    <div>
        <div style="padding: 10px" class="text-center">
            <img
                src="@/assets/logo.png"
                alt="MyDocPort"
                style="max-width: 100%; max-height: 100px; cursor: pointer"
                @mouseleave="hoverLogo = false"
                :class="{ hovered: hoverLogo }"
                @click="
                    () => {
                        hoverLogo = true
                        $router.push('/userdashboard')
                    }
                "
            />
            {{ portal_type }}
        </div>

        <v-list v-for="(item, i) in itemList" :key="i" dense>
            <v-list-group
                v-if="item.children && checkUser(item.perm)"
                v-model="item.model"
                :key="item.text"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
            >
                <v-list-item slot="activator">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    @click="navigate(child)"
                >
                    <v-list-item-action v-if="child.icon">
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item
                v-else-if="checkUser(item.perm)"
                :key="item.text"
                @click="navigate(item)"
            >
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<style>
.hovered {
    transform: scale(0.95);
    transition: all 0.3s ease-in-out;
}
</style>
<script>
import { authGate } from '@/mixins/authGate'

export default {
    name: 'UserNavMenu',
    mixins: [authGate],
    data: () => ({
        hoverLogo: false,
        items: [
            {
                icon: 'mdi-view-dashboard',
                text: 'Announcements',
                route: '/announcements',
                perm: 'client_admin',
            },
            {
                icon: 'mdi-book-open',
                text: 'Manuals',
                route: '/manuals',
                perm: '',
            },
            {
                icon: 'mdi-format-list-numbered',
                text: 'Version History',
                route: '/versions',
                perm: 'client_admin',
            },
            {
                icon: 'mdi-account-multiple',
                text: 'Users',
                route: '/clientusers',
                perm: 'client_admin',
            },
            /*
      {
        icon: "mdi-message",
        text: "Send feedback",
        route: "/userfeedback",
        perm: ""
      },

      { icon: "mdi-help-circle", text: "Help", route: "/userhelp", perm: "" }

       */
        ],
    }),
    computed: {
        itemList: function() {
            let menuItems = this.items.concat(this.buildReportMenuItems())
            let data = menuItems.filter(this.checkTheUser)
            return data
        },
        windowUrl() {
            if (this.$store.getters.userForPortalAccess.client) {
                return this.$store.getters.userForPortalAccess.client
                    .feedback_iframe_target
            } else {
                return null
            }
        },
        portal_type: function() {
            if (this.checkUser('client_admin')) {
                return `${this.$store.getters.userForPortalAccess.client.name} Admin Portal`
            } else if (this.checkUser('admin')) {
                return 'WD Administrator Portal'
            } else {
                return 'Company User Portal'
            }
        },
    },
    methods: {
        navigate: function(item) {
            console.log('Route: ' + item.route)
            this.$router.push({
                path: item.route,
            })
        },
        buildReportMenuItems() {
            let menuItems = []
            const reports =
                this.$store.getters.userForPortalAccess.client.reports || []
            reports.forEach(report => {
                menuItems.push({
                    icon: this.getIcon(report.type),
                    text: report.name,
                    route:
                        '/reports/' +
                        this.$store.getters.userForPortalAccess.client.id +
                        '/' +
                        report.id +
                        '/view',
                    perm: report.perm,
                })
            })

            return menuItems
        },
        checkTheUser(item) {
            return this.checkUser(item.perm)
        },
        getIcon(type) {
            if (type == 'Single') {
                return 'mdi-chart-bar'
            } else {
                return 'mdi-ticket'
            }
        },
    },
}
</script>
