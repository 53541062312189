<template>
    <v-app-bar fixed app dark color="primary">
        <v-app-bar-nav-icon v-if="$auth.isAuthenticated && drawernav" @click.stop="toggleDrawer" />
        <v-toolbar-title>
            <router-link :to="{ name: 'dashboard' }" class="white--text">
                MyDocPort Administration
            </router-link>
        </v-toolbar-title>
        <v-spacer />

        <template v-if="!$auth.isAuthenticated && !$auth.loading">
            <v-btn class="ma-2" text small @click.prevent="login">{{
                $t('login')
                }}</v-btn>
        </template>
        <template v-else>
            <v-btn class="ma-2" text small :to="{ name: 'profile' }">{{
                this.$store.getters.userForPortalAccess.name
                }}</v-btn>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="ma-2" v-if="hasDelegatedUser" @click.prevent="switchToSuperAdmin"
                        style="color: #ffa500;">
                        mdi-account-switch
                    </v-icon>
                </template>
                <span>Switch back to Administrator</span>
            </v-tooltip>
            <v-btn class="ma-2" text small @click.prevent="logout">{{
                $t('logout')
                }}</v-btn>
        </template>
    </v-app-bar>
</template>

<script>
import { getInstance } from '../../auth/authWrapper'
import { mapGetters } from 'vuex'

export default {
    props: {
        drawer: {
            type: Boolean,
            required: true,
        },
        drawernav: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            authUser: 'authUser',
            delegatedUser: 'delegatedUser',
        }),
        hasDelegatedUser() {
            return this.delegatedUser != null
        },
    },
    data: () => ({
        appName: 'MyDocPort',
        busy: false,
    }),
    methods: {
        async switchToSuperAdmin() {
            const authService = getInstance()
            await authService.switchToSuperAdmin()
        },
        toggleDrawer() {
            this.$emit('toggleDrawer')
        },
        // Log the user in
        login() {
            this.$auth.loginWithRedirect()
        },
        logout() {
            this.$auth.logout({
                returnTo:
                    window.location.protocol +
                    '//' +
                    window.location.host
            })
        },
    },
}
</script>
