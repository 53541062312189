<template>
    <div>
        <!-- Admin dashboard section -->
        <v-card v-if="!showCreateNews">
            <v-card-title>
                <h3>WD Administrator Dashboard</h3>
                <!-- Search bar for filtering tasks -->
                <v-spacer />
                <v-text-field
                    v-model="keywords"
                    append-icon="mdi-magnify"
                    label="Search Tasks..."
                    clearable
                    single-line
                    hide-details
                />
            </v-card-title>
            <v-container fluid v-if="isSuperAdmin">
                <v-row>
                    <v-col cols="12">
                        <output-target-grid
                            build_type="all"
                            show_favorites="yes"
                            :reload.sync="reloadGrid"
                        />
                    </v-col>
                </v-row>
            </v-container>

            <template
                v-if="
                    this.$store.getters.userForPortalAccess.user_type ===
                        'client_user' ||
                        this.$store.getters.userForPortalAccess.user_type ===
                            'client_admin'
                "
            >
                <v-col cols="12" class="text-left">
                    <h5 class="body-1">
                        Announcements
                        <v-btn icon @click="showMessage">
                            <v-icon>mdi-email</v-icon>
                        </v-btn>
                    </h5>
                </v-col>
                <NewsCard
                    ref="newsCard"
                    v-if="showNews"
                    style="font-size: 15px;line-height: 1.5;margin: 10px; margin-top: -15px;"
                    @go-back="goBack"
                    @close-dialog="showNews = false"
                />
            </template>

            <v-card-text>
                <!-- Iterate over unread notifications and display notification messages -->
                <notification-message
                    v-for="item in $store.getters.userForPortalAccess
                        .unreadNotifications"
                    :key="item.id"
                    :message="item"
                />
            </v-card-text>
        </v-card>

        <!-- Create news section -->
        <create-news v-if="showCreateNews" @go-back="goBack" />
    </div>
</template>

<script>
import NewsCard from '@/views/news/NewsCard.vue'
import NotificationMessage from '@/components/Layout/NotificationMessage'
import OutputTargetGrid from '@/views/output-target/OutputTargetGrid'
import { mapGetters } from 'vuex'
export default {
    name: 'Dashboard',
    components: {
        'notification-message': NotificationMessage,
        NewsCard,
        'output-target-grid': OutputTargetGrid,
    },
    mounted() {
        if (
            this.$store.getters.userForPortalAccess.user_type ===
                'client_user' ||
            this.$store.getters.userForPortalAccess.user_type === 'client_admin'
        ) {
            this.showNewsCard()
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'authUser',
        }),
        news_query_vars: function() {
            if (this.$store.getters.userForPortalAccess.client) {
                return {
                    client: this.$store.getters.userForPortalAccess.client.id,
                }
            } else {
                return {}
            }
        },
        query_vars() {
            return {
                client_id: this.$store.getters.userForPortalAccess.client.id,
            }
        },
        isSuperAdmin() {
            return this.$store.getters.userForPortalAccess.user_type === 'admin'
        },
    },
    data: () => ({
        deleteDialog: false, // Flag to show/hide delete confirmation dialog
        selectedTarget: {}, // Object that holds the currently selected item for deletion
        rando: 0, // Random number for testing purposes
        keywords: '', // Search keywords for filtering result
        showNews: false,
        showCreateNews: false,
        reloadGrid: 0,
    }),
    methods: {
        showNewsCard() {
            this.$apollo
                .query({
                    query: require('@/graphql/news/allNews.gql'),
                    variables: this.news_query_vars(),
                })
                .then(response => {
                    const data = response.data

                    if (data && data.allNews.length > 0) {
                        this.$nextTick(() => {
                            this.showNews = true
                            // this.$refs.newsCard.menu = true;
                            // this.$refs.newsCard.showModal = true; // Mostrar el modal solo si hay noticias
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        },
        showMessage() {
            this.showNews = true
            this.$nextTick(() => {
                // this.$refs.newsCard.menu = true;
            })
        },
        goBack() {
            this.showNews = false
        },
        reloadTheGrid: function() {
            this.reloadGrid++
        },
    },
}
</script>
<style></style>
