<template>
    <v-app light>
        <tool-bar />
        <v-main>
            <v-container fluid>
                <transition name="page" mode="out-in">
                    <router-view />
                </transition>
            </v-container>
        </v-main>
        <feedback-message />
        <page-footer />
    </v-app>
</template>

<script>
import SimpleToolBar from '@/components/Layout/SimpleToolBar'
import FeedbackMessage from '@/components/Layout/FeedbackMessage'
import PageFooter from '@/components/Layout/PageFooter'

export default {
    components: {
        'tool-bar': SimpleToolBar,
        'feedback-message': FeedbackMessage,
        'page-footer': PageFooter,
    },
    props: {
        source: String,
    },
    data: () => ({}),
}
</script>
