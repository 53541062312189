var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","offset-sm2":"","lg4":"","offset-lg4":""}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ validate, reset, invalid }){return [_c('v-form',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Reset Your Password")])]),_c('v-card-text',[(_vm.formLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|confirmed:confirmpass"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.formLoading,"append-icon":_vm.showpass
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off',"type":_vm.showpass
                                                    ? 'text'
                                                    : 'password',"label":"Password"},on:{"click:append":function($event){_vm.showpass = !_vm.showpass}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required","vid":"confirmpass"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.formLoading,"append-icon":_vm.showpass
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off',"type":_vm.showpass
                                                    ? 'text'
                                                    : 'password',"label":"Confirm Password"},on:{"click:append":function($event){_vm.showpass = !_vm.showpass}},model:{value:(
                                                _vm.formData.confirm_password
                                            ),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"\n                                                formData.confirm_password\n                                            "}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":"","disabled":_vm.formLoading},on:{"click":function($event){return _vm.$emit('password-form-closed')}}},[_vm._v(_vm._s(_vm.$t('close')))]),_c('v-btn',{attrs:{"color":"primary darken-1","text":"","loading":_vm.formLoading,"disabled":_vm.formLoading},on:{"click":function($event){return _vm.updatePass()}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }